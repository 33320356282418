import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import productImage from "../assets/img/apicreating.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const CourseFontEnd = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Web Developer", "Product", "Web Design"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="course" id="course">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">ຄອ​ສສອນ ພື້ນຖານການສ້າງ API ດ້ວຍ NodeJS + Express + MongoDB + Docker</span>

                  <p>ສຳລັບຄົນທີ່ຢາກເປັນ Backend Engineer ເລີ່ມຈາກ 0 ບໍ່ມີພື້ນຖານກໍ່ສາມາດຮຽນໄດ້</p>
                  <p>ຕົວຢ່າງເນື້ອຫາການຮຽນໃນເບື້ອງຕົ້ນ:</p>
                  <p>1. ພື້ນຖານ NodeJS, ການສ້າງ API ດ້ວຍ Express, ການອອກແບບ API</p>
                  <p>2. ການເຊື່ອມຕໍ່ NodeJS ກັບ MongoDB, ການອອກແບບ Database Schema, ອອກແບບ Folder Structure</p>
                  <p>3. ການ Setup ENV file, ຕິດຕັ້ງ JWT ແລະ Middleware, ການ Setup Dockerfile ແລະ Docker Compose, ການນຳໃຊ້ Git</p>
                  <p>4. ການລົງທະບຽນ Cloud Hosting, Setup Ubuntu Server, Deploy ລະບົບເທິງ Ubuntu Server</p>
                  <p>5. Register Domain Name ແລະ Setup DNS, Setup SSL ສຳຫຼັບ API ແລະ ແນະນຳວິທີການ Setup ໃສ່ Website</p>
                  <p>6. Setup Mail Server (ເຊັ່ນ: info@mydomain.com), ການສົ່ງ Mail ຜ່ານ NodeJS</p>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={productImage} alt="Product Img" />
                </div>}
            </TrackVisibility>
          </Col>
          
          
        </Row>
      </Container>
    </section>
  )
}
