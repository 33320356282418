import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import productImage from "../assets/img/reactjscourse.jpg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from 'react-i18next';


export const Course = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Web Developer", "Product", "Web Design"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }
  const [lang, setLang] = useState("la");
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang)
    localStorage.setItem("userLang", lang);
  };
  return (
    <section className="course" id="course">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">ຄອ​ສສອນ ພື້ນຖານ FullStack Development + AI + WorkShop</span>

                  <p> ຕົວຢ່າງເນື້ອຫາການຮຽນໃນເບື້ອງຕົ້ນ (ມືໃໝ່ ເລີ່ມຈາກ 0 ສຸດທີ່ລະດັບ Pre-Junior ບໍ່ມີພື້ນຖານກໍ່ສາມາດຮຽນໄດ້)</p>
                  <p>1. Part 1 : ພື້ນຖານ (Basic Concepts)</p>
                  <ul>- React + TypeScript ພື້ນຖານ</ul>
                  <ul>- HTML, CSS, JavaScript ພື້ນຖານ</ul>
                  <ul>- Node.js & MongoDB</ul>
                  <ul>- ການນຳໃຊ້ AI ເຂົ້າຊ່ວຍໃນການຂຽນລະບົບ</ul>
                  <p>2. Part 2: Full-Stack Development  </p>
                  <ul>- Front-end Development (React + TypeScript)</ul>
                  <ul>- Back-end Development (Node.js + MongoDB)</ul>
                  <p>3. Part 3: Workshop ລະບົບໂຮງແຮມຂະໜາດນ້ອຍ  </p>
                  <ul>- ອອກແບບ ແລະ ພັດທະນາລະບົບ</ul>
                  <ul>- Deploy ລະບົບຂື້ນຄາວແທ້</ul>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={productImage} alt="Product Img" />
                </div>}
            </TrackVisibility>
          </Col>


        </Row>
      </Container>
    </section>
  )
}
